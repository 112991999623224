<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <!-- <Button
          label="Add New"
          icon="pi pi-plus"
          class="ml-2 p-button-success"
          @click="$router.push('packages/add')"
        /> -->
        <!-- <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="show {first} to {last} from {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          Pakete Aufführen

          <div style="text-align: left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Select Columns"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>

      <Column :expander="true" headerStyle="width: 3rem" />

      <Column
        filterMatchMode="contains"
        field="id"
        header="ID"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['id']"
            class="p-column-filter"
            placeholder="Search By ID"
          />
        </template>
      </Column>

      <Column filterMatchMode="contains" field="image" header="Image">
        <template #body="slotProps">
          <img
            :src="$baseUploadURL + slotProps.data.image"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="name.en"
        header="Name (EN)"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['name.en']"
            class="p-column-filter"
            placeholder="Search By Name EN"
            filterMatchMode="contains"
          />
        </template>
        <template #body="slotProps">
          {{ JSON.parse(slotProps.data.name).de }}
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="name.de"
        header="Name (DE)"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['name.de']"
            class="p-column-filter"
            placeholder="Search By Name DE"
            filterMatchMode="contains"
          />
        </template>
        <template #body="slotProps">
          {{ JSON.parse(slotProps.data.name).de }}
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="id"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <!-- <Button
            type="button"
            @click="$router.push('admins/roles/' + slotProps.data.id)"
            icon="pi pi-key"
            v-if="slotProps.data.id != 1"
            class="p-button-info ml-2  p-button-rounded"
          ></Button> -->
          <Button
            type="button"
            @click="$router.push('packages/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            class="p-button-success p-mr-2 p-button-rounded"
          ></Button>
          <!-- <Button
            type="button"
            icon="pi pi-trash"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning ml-2 p-button-rounded"
          ></Button> -->
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="slotProps.data.packagesPricesList"
            class="p-datatable-customers table table-striped"
          >
            <template #header>
              <div class="table-header">
                <div style="float: left; display: inline">Prices Aufführen</div>
                <div style="text-align: right">
                  <Button
                    label="Add New Price"
                    icon="pi pi-plus"
                    class="ml-2 p-button-success"
                    @click="openDialog(slotProps.data.id)"
                  />
                </div>
              </div>
            </template>

            <template #empty>Keine Daten</template>

            <Column filterMatchMode="contains" field="id" header="#" />

            <Column field="name.en" header="Name (EN)">
              <template #body="slotProps5">
                {{ JSON.parse(slotProps5.data.name).de }}
              </template>
            </Column>
            <Column field="name.de" header="Name (DE)">
              <template #body="slotProps5">
                {{ JSON.parse(slotProps5.data.name).de }}
              </template>
            </Column>

            <Column filterMatchMode="contains" field="price" header="Price" />
            <!-- <template #body="slotProps1">
                <input
                  type="number"
                  class="form-control"
                  name="stoptime"
                  id="stoptime"
                  v-model.number="slotProps1.data.stoptime"
                  style="width: 100%;"
                  @change="dx(slotProps1.data)"
                />
              </template>
            </Column> -->
            <Column filterMatchMode="contains" field="id">
              <template #body="slotProps1">
                <Button
                  type="button"
                  @click="deletePrice(slotProps1.data.id)"
                  icon="pi pi-trash"
                  class="p-button-warning p-button-rounded"
                ></Button>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>

    <Dialog
      header="Add Price"
      :visible.sync="displayProductsAdd"
      :style="{ width: '50vw' }"
    >
      <div class="p-m-0">
        <div class="mb-3">
          <label for="name" class="form-label required">
            Name (EN)
            <span>*</span>
          </label>
          <input class="form-control" v-model="d.name.en" />
        </div>
        <div class="mb-3">
          <label for="name" class="form-label required">
            Name (DE)
            <span>*</span>
          </label>
          <input class="form-control" v-model="d.name.de" />
        </div>

        <div class="mb-3">
          <label for="price" class="form-label required">
            Price
            <span>*</span>
          </label>
          <input
            class="form-control"
            v-model.number="d.price"
            type="number"
            min="1"
          />
        </div>
      </div>
      <template #footer>
        <Button
          label="Hinzufügen"
          icon="pi pi-check"
          @click="addProducts()"
          autofocus
        />
        <Button
          label="Colose"
          icon="pi pi-times"
          @click="cDialog()"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expandedRows: [],
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
      disabledAdd: false,
      displayProductsAdd: false,
      d: {
        name: { en: null, de: null },
        price: 0,
        packagesId: null,
      },
    };
  },
  methods: {
    addProducts() {
      if (this.d.name.de && this.d.name.en && this.d.price > 0) {
        this.displayProductsAdd = false;
        this.$http.post(`packages_prices`, this.d).then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.d = {
              name: { en: null, de: null },
              price: 0,
              packagesId: null,
            };

            this.getData();
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      }
    },
    deletePrice(id) {
      this.$http.delete(`packages_prices/${id}`).then(
        () => {
          this.getData();
          this.$toast.add({
            severity: "error",
            summary: "Done Successfully",
            detail: "Deleted successfully",
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    openDialog(id) {
      this.displayProductsAdd = true;
      this.d.packagesId = id;
    },
    cDialog() {
      this.displayProductsAdd = false;
      this.d = {
        name: { en: null, de: null },
        price: 0,
        packagesId: null,
      };
    },
    getData() {
      this.$http.get(`packages`).then(
        (response) => {
          this.loading = false;
          this.list = response.data.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    deleteItem(id) {
      this.$confirm.require({
        message: "Are you sure you want to delete records?",
        header: "Yes Delete Record",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$http.delete(`packages/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: "error",
                summary: "Done Successfully",
                detail: "Deleted successfully",
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
        },
      });
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
